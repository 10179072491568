import { FC, ReactElement } from 'react';

import { appInject } from '@core/di/utils';
import { Navigate } from '@shared/components/router';
import { DI_TOKENS } from '@shared/constants/di';
import { ROUTES } from '@shared/constants/routes';
import { IAuthService } from '@shared/types/auth-service';
import { IUsersService } from '@shared/types/users-service';
import { useLocation } from 'react-router-dom';

export interface AuthGuardProps {
  children: ReactElement;
}

export const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);
  const usersService = appInject<IUsersService>(DI_TOKENS.usersService);
  let location = useLocation();
  if (!authService.isLoggedIn) {
    return <Navigate to={ROUTES.public.login()} state={{ from: location }} replace />;
  } else if (
    authService.isLoggedIn &&
    !usersService.isOnboardingPass &&
    location.pathname !== ROUTES.onboarding
  ) {
    return <Navigate to={ROUTES.onboarding} state={{ from: location }} replace />;
  }

  return children;
};
