import { FC, ReactElement } from 'react';

import { appInject } from '@core/di/utils';
import { Navigate } from '@shared/components/router';
import { DI_TOKENS } from '@shared/constants/di';
import { ROUTES } from '@shared/constants/routes';
import { IAuthService } from '@shared/types/auth-service';
import { useLocation } from 'react-router-dom';

export interface UnauthGuardProps {
  children: ReactElement;
}

export const UnauthGuard: FC<UnauthGuardProps> = ({ children }) => {
  const authService = appInject<IAuthService>(DI_TOKENS.authService);
  let location = useLocation();
  if (authService.isLoggedIn) {
    return <Navigate to={ROUTES.mobilePrivate.dashboard} state={{ from: location }} replace />;
  }

  return children;
};
