import { FC, ReactElement } from 'react';

import { Theme, useMediaQuery } from '@mui/material';
import { Navigate } from '@shared/components/router';
import { ROUTES } from '@shared/constants/routes';
import { Layout } from '@shared/utils/layout';

export interface ViewportGuardProps {
  children: ReactElement;
}

export const ViewportDesktopGuard: FC<ViewportGuardProps> = ({ children }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(Layout.tablet));

  if (!isMobile) {
    return children;
  } else {
    return <Navigate to={ROUTES.mobilePrivate.dashboard} replace />;
  }
};
